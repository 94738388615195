import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    margin: 5,
    boxSizing: 'inherit',
    border: 'none',
    textDecoration: 'none',
    padding: 15,
    backgroundColor: theme.palette.gray0,
    color: theme.palette.gray50,
    textTransform: 'none',

    '&$selected': {
      backgroundColor: theme.palette.white,
      color: theme.palette.text.link,
      textDecoration: 'none',
    },
    '&:disabled': {
      color: theme.palette.gray10,
      cursor: 'not-allowed',
    },
    '&:hover': {
      backgroundColor: theme.palette.gray5,
      textDecoration: 'none',
    },
  },

  tooltip: {
    backgroundColor: theme.palette.tooltip.background,
    color: theme.palette.white,
  },

  disabled: {
    color: theme.palette.gray10,
    cursor: 'not-allowed',
  },

  selected: {
    backgroundColor: theme.palette.white,
    color: theme.palette.text.link,
    textDecoration: 'none',
  },

  icon: {
    marginRight: 5,
  },
}));
