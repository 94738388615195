import React from 'react';
import cn from 'classnames';
import MUIToggleButton from '@material-ui/lab/ToggleButton';

import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';
import { Icon as IconType } from 'components/Icon/Icon';
import { truncate } from 'utils/strings';

import useStyles from './useStyles';

interface ToggleButtonProps {
  className?: string;
  children?: any;
  disabled?: boolean;
  disableFocusRipple?: boolean;
  disableRipple?: boolean;
  onChange?: (event: any, value: any) => void;
  onClick?: (event: any, value: any) => void;
  selected?: boolean;
  size?: 'small' | 'medium' | 'large';
  value?: any;
  truncateText?: boolean;
  icon?: IconType;
  iconWidth?: number;
  iconHeight?: number;
}

const ToggleButton = ({
  className,
  children,
  disabled,
  disableFocusRipple,
  disableRipple,
  onChange,
  onClick,
  selected,
  size,
  value,
  truncateText,
  icon,
  iconWidth,
  iconHeight,
  ...rest
}: ToggleButtonProps) => {
  const classes = useStyles();

  const buttonText = () => {
    if (truncateText) {
      if (size === 'small') {
        return truncate(children, 2);
      }
      if (size === 'medium') {
        return truncate(children, 4);
      }
      if (size === 'large') {
        return truncate(children, 8);
      }
    }
    return children;
  };

  const buttonProps = {
    classes,
    className: cn(
      classes.root,
      {
        [classes.disabled]: disabled,
        [classes.selected]: selected,
      },
      className,
    ),
    children: buttonText(),
    disabled,
    disableFocusRipple,
    disableRipple,
    selected,
    size,
    value,
    truncateText,
    ...rest,
  };

  const handleChange = event => {
    if (onClick) {
      onClick(event, value);
      if (event.isDefaultPrevented()) {
        return;
      }
    }
    if (onChange) {
      onChange(event, value);
    }
  };

  return truncateText ? (
    <Tooltip className={classes.tooltip} placement="bottom" title={children}>
      <MUIToggleButton onClick={handleChange} {...buttonProps}>
        {icon && <Icon icon={icon} width={iconWidth} height={iconHeight} className={classes.icon} />}
        {children && <span>{buttonText()}</span>}
      </MUIToggleButton>
    </Tooltip>
  ) : (
    <MUIToggleButton onClick={handleChange} {...buttonProps}>
      {icon && <Icon icon={icon} width={iconWidth} height={iconHeight} className={classes.icon} />}
      {children && <span>{children}</span>}
    </MUIToggleButton>
  );
};

export default ToggleButton;
