import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: `0 1px 3px 0 ${theme.palette.CHANGEME[40]}`,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14),
    padding: theme.spacing(2),
    maxWidth: '25vw',
  },

  // button should be an invisible wrapper to enable keyboard focus
  button: {
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    margin: 0,
    lineHeight: 'unset',
    color: 'inherit',
  },
}));
