import React, { ReactNode, MouseEvent } from 'react';
import cn from 'classnames';
import MUIToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '../Paper';
import isValueSelected from './isValueSelected';
import useStyles from './useStyles';

interface ToggleButtonGroupProps {
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  exclusive?: boolean;
  onChange?: (event: MouseEvent<HTMLElement>, value: any) => void;
  orientation?: 'horizontal' | 'vertical';
  size?: 'large' | 'medium' | 'small';
  value?: string | number | string[];
}

const ToggleButtonGroup = ({
  className,
  children,
  onChange,
  orientation = 'horizontal',
  size = 'medium',
  value,
  disabled,
  ...rest
}: ToggleButtonGroupProps) => {
  const classes = useStyles();

  const toggleButtonGroupProps = {
    component: 'div',
    role: 'group',
    orientation,
    exclusive: true,
    onChange,
    value,
    disabled,
    ...rest,
  };

  return (
    <Paper className={classes.paper}>
      <MUIToggleButtonGroup
        {...toggleButtonGroupProps}
        className={cn(classes.root, className, {
          [classes.vertical]: orientation === 'vertical',
          [classes.horizontal]: orientation === 'horizontal',
        })}
      >
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return React.cloneElement(child, {
            className: cn(child.props.className, {
              [classes.groupedVertical]: orientation === 'vertical',
              [classes.groupedHorizontal]: orientation === 'horizontal',
              [classes.sizeSmall]: size === 'small',
              [classes.sizeMedium]: size === 'medium',
              [classes.sizeLarge]: size === 'large',
            }),
            onChange,
            selected: isValueSelected(child.props.value, value),
            size: child.props.size || size,
            truncateText: child.props.truncateText,
            disabled,
          });
        })}
      </MUIToggleButtonGroup>
    </Paper>
  );
};

export default ToggleButtonGroup;
