import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import useStyles from './useStyles';
import { buildEditorState, useErrors } from './utils';

const TOOLBAR_OPTIONS = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'link',
    'list',
    'textAlign',
    'colorPicker',
    'remove',
    'history',
  ],
};

interface WysiwygProps {
  editableContent?: string;
  onChange?: (html: string) => void | React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  className?: string;
  maxContentLength?: number;
  rest?: React.ComponentProps<typeof Editor>;
}

const Wysiwyg = ({
  editableContent = '',
  onChange = undefined,
  placeholder = '',
  error = undefined,
  helperText = '',
  className = '',
  maxContentLength,
  ...rest
}: WysiwygProps) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(() => buildEditorState(editableContent));
  const [htmlContent, setHtmlContent] = useState('');

  const { hasErrors, errorMessagesArray } = useErrors(
    htmlContent,
    !!error,
    helperText,
    maxContentLength
      ? {
          maxContentLength,
        }
      : {},
  );

  const handleEditorStateChange = (value: EditorState) => {
    setEditorState(value);

    const contentState = value.getCurrentContent();
    const html = contentState.hasText() ? draftToHtml(convertToRaw(contentState)) : '';
    setHtmlContent(html);
    onChange?.(html);
  };

  return (
    <div
      className={cn({
        [classes.root]: true,
        [classes.error]: error,
        [className]: className,
      })}
      data-node="wysiwyg_editor"
    >
      <Editor
        toolbar={TOOLBAR_OPTIONS}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        placeholder={placeholder}
        stripPastedStyles={false}
        // this is necessary to prevent changes to the formatting when pasted in
        handlePastedText={() => false}
        spellCheck
        {...rest}
      />
      <div
        className={cn({
          [classes.noError]: !hasErrors,
          [classes.errorText]: hasErrors,
        })}
      >
        {errorMessagesArray.map(msg => (
          <p key={msg}>{msg}</p>
        ))}
      </div>
    </div>
  );
};

Wysiwyg.propTypes = {
  editableContent: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  className: PropTypes.string,
};

export default Wysiwyg;
