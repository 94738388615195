import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  filterMenuInput: {
    cursor: 'pointer',
    padding: 10,
    border: `1px solid ${theme.palette.gray10}`,
    borderRadius: 5,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    minHeight: 40,
  },
  filterMenuPlaceholder: {
    position: 'absolute',
    left: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  listItem: {
    padding: 5,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.blue65,
      color: theme.palette.white,
    },
    '&.selected': {
      backgroundColor: theme.palette.gray45,
      color: theme.palette.white,
    },
  },
  label: {
    marginLeft: 5,
    width: 222,
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.25),
    backgroundColor: theme.palette.gray5,
    border: `1px solid ${theme.palette.gray30}`,
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    paddingRight: 24,
  },
  icon: {
    color: theme.palette.gray80,
  },
  content: {
    paddingLeft: 0,
    borderRadius: 0,
    height: 'inherit',
  },
  input: {
    display: 'none',
  },
  textField: {
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  group: {
    paddingLeft: 10,
  },
  groupName: {
    fontWeight: 'bold',
    padding: '10px 0',
  },
}));
