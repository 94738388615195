import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  maxLengthHelperText: {
    display: 'flex',
  },
  maxLength: {
    marginLeft: 'auto',
    paddingLeft: '1ch',
  },
});
