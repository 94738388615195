import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  searchItem: {
    outline: 'none',
    padding: 0,
    borderBottom: `2px solid ${theme.palette.border}`,
  },

  emptyItem: {
    outline: 'none',
    padding: 0,
  },

  emptyState: {
    padding: 35,
    width: '100%',
  },
}));
