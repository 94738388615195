import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { isEmpty, isNil } from 'ramda';
import { useDispatch } from 'react-redux';

import Typography from 'components/Typography';
import TextField from 'components/TextField';
import Form from 'forms/CreateResourceForm';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import Wysiwyg from 'components/Wysiwyg';

import getHooksForRepository from 'hooks/getHooksForRepository';

import IntegrationsRepository from 'repositories/IntegrationsRepository';
import { IntegrationsContext } from 'components/IntegrationsPanel';

import { addSuccessNotification, addErrorNotification } from 'sharedContainers/Notifications/NotificationsActions';

import useStyles from './useStyles';

interface CreateResourceProps {
  onClose: () => void;
  formValue: any;
}

const { useCreate, useUpdate } = getHooksForRepository(IntegrationsRepository);

const CreateResource = ({ onClose, formValue }: CreateResourceProps) => {
  const classes = useStyles();

  const [form, setForm] = useState(() => Form.defaultAttributes());
  const [errors, setErrors] = useState<any>({});
  const dispatch = useDispatch();

  const { toolId } = useContext(IntegrationsContext);

  useEffect(() => {
    setForm(formValue);
  }, [formValue]);

  const handleChange = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [field]: e.target.value });
  };

  const onCloseModal = () => {
    setErrors({});
    onClose();
  };

  const { mutate: create } = useCreate({
    onSuccess() {
      dispatch(addSuccessNotification('Resource created succesfully'));
      onCloseModal();
    },
    onError() {
      dispatch(addErrorNotification('Error creating resource'));
    },
  });

  const { mutate: update } = useUpdate({
    onSuccess() {
      dispatch(addSuccessNotification('Resource updated succesfully'));
      onCloseModal();
    },
    onError() {
      dispatch(addErrorNotification('Error updating resource'));
    },
  });

  const handleSubmit = () => {
    const formErrors = Form.validate(form);
    if (isEmpty(formErrors)) {
      isEmpty(formValue) ? create(toolId, form) : update(toolId, formValue.id, form);
    } else {
      setErrors(formErrors);
    }
  };

  const handleChangeContent = field => value => {
    setForm({ ...form, [field]: value });
  };

  const { id: itemId, name, description, content } = form;
  return (
    <div key={itemId} className={classes.modalContent} data-node="create-resource-modal">
      <Grid container spacing={3} direction="column">
        <Grid item>
          <Typography className={classes.label}>Title of Resource</Typography>
          <TextField
            value={name}
            onChange={handleChange('name')}
            fullWidth
            required
            size="small"
            variant="outlined"
            placeholder="Title"
            id="create-resource-title-field"
            error={!isNil(errors.name)}
            helperText={errors.name ?? 'Required Field *'}
            className={classes.nameField}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>Description</Typography>
          <TextArea
            value={description}
            onChange={handleChange('description')}
            row={3}
            fullWidth
            placeholder="Brief description of the external resource."
            id="create-resource-description-field"
            error={!isNil(errors.description)}
            helperText={errors.description ?? 'Required Field *'}
          />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>Resource Content</Typography>
          <Wysiwyg
            onChange={handleChangeContent('content')}
            editableContent={content}
            placeholder="This is example form text intended to be attached to a product"
            data-node="create-resource-content-field"
            error={!isNil(errors.content)}
            helperText={errors.content ?? 'Required Field *'}
          />
        </Grid>
      </Grid>

      <div className={classes.actions}>
        <Button
          data-node="cancel-resource-button"
          className={classes.closeBtn}
          onClick={onClose}
          variant="contained"
          color="bordered"
          size="small"
        >
          Cancel
        </Button>
        <Button
          className={classes.addArtifactBtn}
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          size="small"
          data-node="create-resource-button"
        >
          {isEmpty(formValue) ? 'Create' : 'Update'}
        </Button>
      </div>
    </div>
  );
};

export default CreateResource;
