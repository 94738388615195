import { useEffect, useMemo } from 'react';
import { isEmpty, match, filter as ramdaFilter, equals } from 'ramda';
import escapeRegExp from 'lodash.escaperegexp';
import { flattenTree } from 'utils/Utils';

import { useListenForEvents } from 'hooks/useEvents';
import useQueryKey from 'hooks/useQueryKey';

import { treeifyTagGroup } from '../../tagGroupUtils';

const searchByName = (items, searchTerm) => {
  const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');
  const search = item => {
    return !isEmpty(match(searchRegex, item.searchTerms || item.name));
  };
  return ramdaFilter(search, items);
};

export const filterItemsBySearchTerm = (items, searchTerm) => {
  return !isEmpty(searchTerm) ? searchByName(flattenTree(items), searchTerm) : items;
};

/**
 * Filters a list of Tag Groups by the search term. Use in menus where we want to provide filtering
 * by text. Returns any tags that match plus their children
 */
export const useFilterTagGroupsBySearchTerm = (tagGroups, searchTerm) => {
  const treeifiedGroups = useMemo(() => tagGroups.map(group => treeifyTagGroup(group)), [tagGroups]);

  const filteredTagGroups = useMemo(
    () =>
      treeifiedGroups.map(group => ({
        ...group,
        tagsListAsTree: filterItemsBySearchTerm(group.tagsListAsTree, searchTerm),
      })),
    [treeifiedGroups, searchTerm],
  );
  const showNoResults = useMemo(
    () => filteredTagGroups.every(group => group.tagsListAsTree && group.tagsListAsTree.length === 0),
    [filteredTagGroups],
  );

  return [filteredTagGroups, showNoResults];
};

const defaultValue = []; // referential identity

/**
 * Manages the state of multiple Tag Groups that can be selected (e.g. from a menu) and consolidates
 * them into a single list of tags IDs synced to the URL
 */
export const useSelectTagsFromMenu = (filter, queryKey) => {
  const [selectedIds, setSelectedIds] = useQueryKey(queryKey, defaultValue);

  // On mount, if there are tag IDs present that aren't in the filter for some reason, remove them
  useEffect(() => {
    if (selectedIds.length && filter.uniqueTagIds) {
      const validIds = selectedIds.filter(tagId => filter.uniqueTagIds.includes(tagId));
      if (!equals(selectedIds, validIds)) {
        setSelectedIds(validIds);
      }
    }
  }, []);

  // Respond to requests from other components
  useListenForEvents((event, payload) => {
    switch (event) {
      case 'REMOVE_FILTER_ITEM':
        if (payload.filterKey === queryKey) {
          setSelectedIds(selectedIds.filter(id => id !== payload.value.id));
        }
        break;
      case 'CLEAR_FILTERS':
        setSelectedIds([]);
        break;
      default:
        break;
    }
  });

  return [selectedIds, setSelectedIds];
};
