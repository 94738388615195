import { createContext } from 'react';
import type { Tool } from 'presenters/ToolPresenter';

type IntegrationsContextType = {
  toolId: number | unknown;
  tool: Tool;
  isProvider: boolean;
  isSysAdmin: boolean;
  canEdit: boolean;
  useUpdateToolVendorComment: (options: any) => any;
  relatedData: any;
};

const IntegrationsContext = createContext({} as IntegrationsContextType);

export default IntegrationsContext;
