import { useState } from 'react';

export type VisibilityState = {
  researchArtifacts: boolean;
  essaEvidence: boolean;
};

type UseModal = [boolean, (key?: string) => void, (key?: string) => void, VisibilityState];

const initialVisiblityState = {
  researchArtifacts: false,
  essaEvidence: false,
};

export default (initialState = false): UseModal => {
  const [isVisible, setIsVisible] = useState(initialState);
  // This handle a scenario where we have multiple modals in the same component
  const [visibilityState, setVisibilityState] = useState(initialVisiblityState);

  const handleShow = (key?: string) => {
    if (key) {
      setVisibilityState({
        ...visibilityState,
        [key]: true,
      });
    }
    setIsVisible(true);
  };

  const handleClose = (key?: string) => {
    if (key) {
      setVisibilityState({
        ...visibilityState,
        [key]: false,
      });
    }
    setIsVisible(false);
  };

  return [isVisible, handleShow, handleClose, visibilityState];
};
