import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import cn from 'classnames';

import styles from './styles';

const TextArea = ({ classes, children, className, error, ...rest }) => (
  <MUITextField
    className={cn(classes.root, className, { [classes.error]: error })}
    multiline
    InputProps={{
      disableUnderline: true,
      classes: {
        root: classes.textareaRoot,
        input: cn(classes.textareaInput, { [classes.textareaError]: error }),
      },
    }}
    InputLabelProps={{
      shrink: true,
    }}
    {...rest}
  >
    {children}
  </MUITextField>
);

TextArea.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
};

TextArea.defaultProps = {
  children: null,
  className: '',
  error: false,
};

export default withStyles(styles)(TextArea);
