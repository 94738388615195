/* eslint-disable react-hooks/rules-of-hooks */
/* eslint no-use-before-define: ["error", { "variables": false }] */

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
  UseMutationOptions,
  UseInfiniteQueryOptions,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { makeStyles } from '@material-ui/core/styles';
import { uuid } from 'uuidv4';
import humps from 'humps';
import { has, equals } from 'ramda';
import * as Types from './getHooksForRepository.types';

const queryRegex = /^(?:index|show)$/;
const mutationRegex = /^(?:create|update|destroy)$/;
const queryTypeRegex = /^(?:query|mutation|infinite)$/;

/**
 * We write the same data fetching logic in Redux Slices many times.
 * These hooks provide a single location to abstract imperative data
 * fetching into a more declarative API suitable for React.
 *
 * Usage:
 * Given a repository object of the form Repo = {
 *   index(p1, p2) {},
 *   show() {},
 *   update() {},
 *   create(p1, p2) {},
 *   destroy() {},
 *   etc...
 * }
 *
 * const { useIndex, useShow, useUpdate, useCreate, useDestroy } = getHooksForRepository(Repo);
 * const Component = () => {
 *   const { data, isLoading, error } = useIndex(param1, param2, **useQueryOpts**);
 *
 *   const { mutate: create, isLoading: isCreating } = useCreate(**useMutationOpts**);
 *   const onClick = () => create(param1, param2);
 *
 *   --rest of component--
 * }
 *
 * Call getHooksForRepository() with a Repository object to get a set of hooks. The hooks returned
 * are the names of the Repository functions prepended with "use" and camel-cased.
 *
 * These hooks manage two types of requests: queries and mutations. Queries declaratively load data,
 * while mutations imperatively modify data on the server. getHooksForRepository() deduces that
 * index() and show() are queries, and update(), create(), and destroy() are mutations. Other
 * Repository functions must be explicitly overridden with ex.
 * getHooksForRepository(Repo, { [name]: 'query' | 'mutation' | 'infinite' }).
 *
 * Query hooks return an object with { data, isLoading, error } fields, among others. See
 * https://react-query.tanstack.com/reference/useQuery for details. These fields should play
 * nicely with our existing Redux slices and make it easy to refactor if desired.
 *
 * Mutation hooks return an object with a { mutate() } field to fire the request, among others.
 * The mutation hooks can take an object with { onSuccess(), onError(), onMutate() } callbacks.
 * See https://react-query.tanstack.com/reference/useMutation for details.
 *
 * Note: the mutation hooks are automatically set up to invalidate the queries on the same
 * repository, no work required. After a mutation has been made, the queries will be refetched
 * to contain the most up to date information. This metric can be overaggressive but will prevent
 * stale data.
 *
 * Infinite queries are a special type of query that handles page and perPage for you.
 * The only work you need to do is pass a function that takes the forwards all of the params
 * to the Repository function. Return an array to forward multiple arguments, and use a perPage
 * field in the second parameter to change the default perPage of 20.
 *
 * For example:
 *   const result1 = useIndex1(({ page, perPage }) => ({ page, perPage, somethingElse: 'foo' }))
 *   const result2 = useIndex2(({ page, perPage }) => [orgId, { page, perPage, somethingElse: 'foo' }], { perPage: 50, ...**useQueryOpts** });
 * calls
 *   Repository.index1({ page, perPage: 20, somethingElse: 'foo' })
 *   Repository.index2(orgId, { page, perPage: 50, somethingElse: 'foo' })
 *
 * These hooks use the excellent react-query package under the hood. Though we could roll our own
 * data fetching management with useState() and useEffect(), for a few KB we get caching and
 * garbage collection, cache invalidation, stale-while-revalidate for balancing freshness and
 * immediacy, deduping requests, and more, which for our CRUD-heavy frontend is worth the extra
 * code. It should also pay for itself with the reduction in boilerplate.
 */
const getHooksForRepository = <R extends Types.Repository, O extends Types.Overrides<R>>(
  Repository: R,
  queryOverrides: O = {} as O,
): Types.RepositoryHooks<R, O> => {
  // Make sure Repository.name is a valid unique string, using a UUID if necessary
  ensureRepositoryDotName(Repository);

  const hooks = {};

  Object.keys(Repository).forEach(repoKey => {
    const repoFunc = Repository[repoKey];
    if (typeof repoFunc !== 'function') {
      return;
    }
    const hookName = humps.camelize(`use_${repoKey}`);

    // define hooks as getters so only the ones used are ever created
    Object.defineProperty(hooks, hookName, {
      get: () => {
        // Repository functions tend to be one of "index", "show", "create", "update", and "destroy".
        // For "index" and "show", return an augmented `useQuery`. The others need to be handled
        // separately, returning an augmented `useMutation`. If the function is not named one of
        // those values, a queryOverride must be set. An override may also be set on any name.
        const hasOverride = has(repoKey, queryOverrides);
        const override = queryOverrides[repoKey];
        if (hasOverride && (!override || !queryTypeRegex.test(override))) {
          // include dev-facing string only in dev, may still cause 'undefined is not a function' in prod
          if (import.meta.env.MODE === 'development') {
            throw new Error(`${override} is not a valid query override; doesn't match ${queryTypeRegex.source}.`);
          } else {
            return undefined;
          }
        }
        const queryType = hasOverride
          ? override
          : (queryRegex.test(repoKey) && 'query') || (mutationRegex.test(repoKey) && 'mutation');
        if (!queryType) {
          if (import.meta.env.MODE === 'development') {
            throw new Error(`A query override was not set for ${repoKey}, which is not one of "index", "show", "create", "update", or "destroy".
getHooksForRepository should be called like getHooksForRepository(Repository, { ${repoKey}: "query" | "mutation" | "infinite" })`);
          } else {
            return undefined;
          }
        }

        const cachedHook = getHookFromCache(Repository, repoKey, queryType);
        if (cachedHook) {
          return cachedHook;
        }

        let hook;
        if (queryType === 'query') {
          hook = buildQueryHook(Repository, repoFunc, repoKey);
        } else if (queryType === 'mutation') {
          hook = buildMutationHook(Repository, repoFunc);
        } else if (queryType === 'infinite') {
          hook = buildInfiniteQueryHook(Repository, repoFunc, repoKey);
        }
        putHookIntoCache(Repository, repoKey, queryType, hook);
        // add extra properties to the hook for help in debugging - try console.log(hook)
        Object.defineProperties(hook, {
          name: { value: hookName },
          queryType: { value: queryType },
        });
        return hook;
      },
    });
  });
  return hooks as Types.RepositoryHooks<R, O>;
};
export default getHooksForRepository;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // treat responses under this age (ms) as fresh, and don't fire a revalidate request
      staleTime: 10 * 1000,
      // reuse responses under this age, but then revalidate request and update data if changed
      cacheTime: 5 * 60 * 1000,
      // don't retry failed requests; could be number of retries to attempt
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});
if (import.meta.env.MODE === 'development') {
  // do whatever you want with queryClient in the console!
  (window as any).queryClient = queryClient;
}

/**
 * To use getHooksForRepository, this RepositoryProvider must be above the hooks in the
 * component tree, best at the top-level of the application.
 */
export const RepositoryProvider = ({ children, noDevTools }) => {
  const classes = useDevtoolStyles();
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {!noDevTools && <ReactQueryDevtools toggleButtonProps={{ className: classes.toggleButton }} />}
    </QueryClientProvider>
  );
};
RepositoryProvider.propTypes = {
  children: PropTypes.node,
  noDevTools: PropTypes.bool,
};

/**
 * Returns a function that lets you invalidate & refetch all queries for a given Repository. Use
 * this only when the built-in invalidation isn't enough, such as when one Repository's state
 * affects another's and the first one has been updated. (ex. update TagRepository, then
 * invalidate TagGroupRepository)
 * @example const invalidate = useInvalidateRepository(); const callback = () => invalidate(TagGroupRepository);
 */
export const useInvalidateRepository = () => {
  try {
    const localQueryClient = useQueryClient();
    return useCallback(
      Repository => {
        ensureRepositoryDotName(Repository);
        localQueryClient.invalidateQueries({ queryKey: [Repository.name] });
      },
      [localQueryClient],
    );
  } catch (e) {
    // For Storybook, which doesn't have a query client provided
    return () => {};
  }
};

const useDevtoolStyles = makeStyles({
  toggleButton: {
    filter: 'opacity(50%) saturate(50%)',
    transition: 'filter 500ms',
    transitionDelay: '300ms',
    '&:hover': {
      filter: 'none',
      transitionDelay: '0ms',
    },
  },
});

let repositoryNameInfoMessageShown = false; // development only
const ensureRepositoryDotName = Repository => {
  // If Repository.name is present, use it in the query key for easier debugging. Must be unique.
  const hasRepositoryName = typeof Repository.name === 'string';
  if (import.meta.env.MODE === 'development' && !hasRepositoryName && !repositoryNameInfoMessageShown) {
    // eslint-disable-next-line no-console
    console.info(
      `Consider adding a unique .name field (ex. "ExampleRepository") to Repository objects passed to \
getHooksForRepository(), for a better experience identifying requests with the React Query devtools. For now, \
a UUID will be used to identify the Repository object instead. This message will be stripped in production.`,
    );
    repositoryNameInfoMessageShown = true; // show only once
  }
  if (!hasRepositoryName) {
    // eslint-disable-next-line no-param-reassign
    Repository.name = uuid();
  }
};

const buildQueryHook = (Repository, repoFunc, repoKey) => {
  const queryFn = ({ queryKey }) => repoFunc.apply(Repository, queryKey.slice(2)); // pass ...forwardedArgs from below
  const queryHook = (...args) => {
    const l = repoFunc.length;
    const forwardedArgs = args.slice(0, l);
    const useQueryArgs = args[l] ?? {};
    // special case paginated index queries to keepPreviousData, unless explicitly set to false
    if (
      repoKey === 'index' &&
      forwardedArgs.some(a => a && typeof a === 'object' && has('page', a) && has('per_page', a)) &&
      useQueryArgs.keepPreviousData !== false
    ) {
      useQueryArgs.keepPreviousData = true;
    }
    // Identify the request by Repository name and key
    const queryKey = [Repository.name, repoKey, ...forwardedArgs];
    return {
      ...useQuery(queryKey, queryFn, useQueryArgs),
      // Lots of React Query APIs (e.g. useQueryClient().*) use the query key, but getHooksForRepository() abstracts it.
      // Make the generated query key, which is specific to any applied filters, available in the useQuery() return value.
      queryKey,
    };
  };
  return queryHook;
};

const buildMutationHook = (Repository, repoFunc) => {
  // Take args as array and call repository function with them, and this=Repository
  const mutationFn = argsArray => repoFunc.apply(Repository, argsArray);
  const mutationHook = (mutationOpts: UseMutationOptions & { queryKeyToUpdateFromResponse?: any } = {}) => {
    const localQueryClient = useQueryClient();

    const mutation = useMutation(mutationFn, {
      ...mutationOpts,
      onSuccess: (...args) => {
        // Automatically invalidate all queries on this Repository (queries where Repository.name
        // is first value of query key) when a mutation (POST, PATCH, PUT, DELETE) has succeeded.
        // If `queryKeyToUpdateFromResponse` is given, don't refetch that query; use the response
        // data from the mutation instead. Good for updating `show` query from `update` in one round-trip.
        if (mutationOpts.queryKeyToUpdateFromResponse) {
          localQueryClient.setQueryData(mutationOpts.queryKeyToUpdateFromResponse, args[0]);
          localQueryClient.invalidateQueries([Repository.name], {
            predicate: query => !equals(query.queryKey, mutationOpts.queryKeyToUpdateFromResponse),
          });
        } else {
          localQueryClient.invalidateQueries([Repository.name]);
        }
        if (mutationOpts.onSuccess) {
          mutationOpts.onSuccess(...args);
        }
      },
    });

    return {
      ...mutation,
      mutate: (...args) => {
        // Forward the accepted number of args to repository function as array,
        // taking an additional arg to be the mutate() callbacks { onSuccess, onSettled, onError }
        // Note: it's recommended to pass the callbacks directly to the hook call instead, as part of mutationOpts
        const l = repoFunc.length;
        const forwardedArgs = args.slice(0, l);
        const callbacks = args[l];
        // @ts-ignore
        mutation.mutate(forwardedArgs, callbacks);
      },
      mutateAsync: (...args) => {
        const l = repoFunc.length;
        const forwardedArgs = args.slice(0, l);
        const callbacks = args[l];
        // @ts-ignore
        return mutation.mutateAsync(forwardedArgs, callbacks);
      },
    };
  };
  return mutationHook;
};

const buildInfiniteQueryHook = (Repository, repoFunc, repoKey) => {
  const infiniteQueryHook = (pageParamsFunc, useQueryArgs: UseInfiniteQueryOptions & { perPage?: number } = {}) => {
    const { perPage = 20, ...rest } = useQueryArgs;
    const useInfiniteQueryArgs = {
      keepPreviousData: true,
      ...rest,
      // returns next or previous { page, perPage } or undefined
      getNextPageParam,
      getPreviousPageParam,
    };

    // pageParamsFunc should be cheap to call, because we call it with dummy values to determine
    // if the forwarded arguments have changed, and thus whether to make new requests
    const queryKeysFromDummyCall = useMemo(() => arrayify(pageParamsFunc({ page: 0, perPage: 0 })), [pageParamsFunc]);
    // Identify the request by Repository name, key, and `infinite` marker
    const queryKey = [Repository.name, `${repoKey} infinite`, ...queryKeysFromDummyCall];

    const hookReturnValue = useInfiniteQuery(
      queryKey,
      ({ pageParam = { page: 1, perPage } }) => repoFunc.apply(Repository, arrayify(pageParamsFunc(pageParam))),
      useInfiniteQueryArgs,
    );
    return {
      ...hookReturnValue,
      // patch the fetchNextPage function our components get so that it dedupes requests
      fetchNextPage() {
        if (!hookReturnValue.isFetchingNextPage) {
          hookReturnValue.fetchNextPage();
        }
      },
      // Lots of React Query APIs (e.g. useQueryClient().*) use the query key, but getHooksForRepository() abstracts it.
      // Make the generated query key, which is specific to any applied filters, available in the useQuery() return value.
      queryKey,
    };
  };
  return infiniteQueryHook;
};

// Cache hooks so they can be created on demand and used in multiple places with
// no extra work, and maintain referential equality
// keep cached hooks around for as long as the repository object exists
const globalCache = new WeakMap();
const getHookFromCache = (Repository, repoKey, queryType) => {
  const cachedHooks = globalCache.get(Repository);
  // Because getHooksFromRepository is parameterized by queryOverrides,
  // we have to tag each cached hook with the override used. globalCache
  // will store objects of { index: { query?: hook, mutation?: hook, infinite?: hook } }
  const cachedHook = cachedHooks?.[repoKey]?.[queryType];
  return cachedHook;
};
const putHookIntoCache = (Repository, repoKey, queryType, hook) => {
  let cachedHooks = globalCache.get(Repository);
  if (!cachedHooks) {
    cachedHooks = {};
    globalCache.set(Repository, cachedHooks);
  }
  if (!cachedHooks[repoKey]) {
    cachedHooks[repoKey] = {};
  }
  cachedHooks[repoKey][queryType] = hook;
};

const arrayify = val => (Array.isArray(val) ? val : [val]);

// functions used for infinite queries
const getNextPageParam = lastPage => {
  const meta = lastPage?.meta;
  return meta && typeof meta === 'object' && meta.currentPage < meta.numPages
    ? {
        page: meta.currentPage + 1,
        perPage: meta.perPage,
      }
    : undefined;
};
const getPreviousPageParam = firstPage => {
  const meta = firstPage?.meta;
  return meta && typeof meta === 'object' && meta.currentPage > 1 // 1-based indexing
    ? {
        page: meta.currentPage - 1,
        perPage: meta.perPage,
      }
    : undefined;
};
