import { difference, differenceWith, chain, pluck } from 'ramda';
import { makeTreeFromFlatList } from 'utils/Utils';

// accepts array of tag groups, returns a Set of all tag ids contained
export const getTagIdSetInGroups = tagGroups =>
  new Set(
    pluck(
      'id',
      chain(group => group.tagInfo, tagGroups),
    ),
  );

export const getTagsInTagIdSet = (tags, tagIdSet) => tags.filter(tag => tagIdSet.has(tag.id));

export const makeGroupedOnSelect = (onSelect, selectedTags, selectedTagsInTagGroup) => {
  const groupedOnSelect = newSelectedTagsInTagGroup => {
    const filteredNewSelectedTagsInTagGroup = newSelectedTagsInTagGroup.filter(Boolean);
    // if a tag is added to a tag group in a checklist, it's not already selected/applied
    const addedTags = difference(filteredNewSelectedTagsInTagGroup, selectedTagsInTagGroup);
    // if a tag is removed from a tag group in a checklist, it should be removed overall, from any other lists
    const removedTags = difference(selectedTagsInTagGroup, filteredNewSelectedTagsInTagGroup);
    const newSelectedTags = selectedTags
      .filter(selectedTag => !removedTags.find(tag => tag.id === selectedTag.id))
      .concat(addedTags);
    onSelect(newSelectedTags);
  };
  return groupedOnSelect;
};

/*
 *  treeifyTagGroups - converts a Tag Group into a parent-child hierarchy
 *  Adds a property to the `groups` parameter called tagsListAsTree
 */
export const treeifyTagGroup = group => ({ ...group, tagsListAsTree: makeTreeFromFlatList(group.tagInfo) });

// When updating an entity that uses tag groups (Filters, Lists, and Tag Columns), send a "diff" that contains
// the groups to add and the groups to "_destroy" instead of a replacing the set of groups.
export const diffTagGroupRelationshipsAttributes = (originalGroups, newGroups) => {
  const tagGroupsRemoved = differenceWith((x, y) => x.id === y.id, originalGroups, newGroups);
  const tagGroupRelationshipsAttributes = [
    ...newGroups.map((tg, i) => ({
      id: originalGroups.find(orig => orig.id === tg.id)?.tagGroupRelationId,
      tagGroupId: tg.id,
      sortOrder: i,
    })),
    ...tagGroupsRemoved.map(tg => ({
      id: originalGroups.find(orig => orig.id === tg.id)?.tagGroupRelationId,
      tagGroupId: tg.id,
      _destroy: true,
    })),
  ];
  return tagGroupRelationshipsAttributes;
};
