import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import { ListItem } from '@material-ui/core';
import FiltersInput from 'components/Filters/FiltersInput';
import NoResults from 'components/NoResults';
import { filterItemsBySearchTerm } from 'components/Tags/TagMenu/hooks/TagMenuHooks';
import useStyles from './useStyles';

const SearchableMenu = ({ items, renderItems, initialSearchTerm, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const filteredItems = useMemo(() => filterItemsBySearchTerm(items, searchTerm), [searchTerm]);

  const classes = useStyles();
  return (
    <>
      <ListItem className={classes.searchItem} disableGutters dense>
        <FiltersInput
          autoFocus
          value={searchTerm}
          onChangeInput={setSearchTerm}
          startIcon="search"
          placeholder={placeholder}
          inputProps={{
            'aria-label': { placeholder },
          }}
          clearable
        />
      </ListItem>
      {isEmpty(filteredItems) && (
        <ListItem className={classes.emptyItem} disableGutters dense>
          <NoResults icon="emptyState3" className={classes.emptyState} />
        </ListItem>
      )}
      {renderItems(filteredItems)}
    </>
  );
};

SearchableMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  renderItems: PropTypes.func,
  initialSearchTerm: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchableMenu.defaultProps = {
  initialSearchTerm: '',
  renderItems: () => {},
  placeholder: 'Type to search...',
};

export default SearchableMenu;
