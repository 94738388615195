import { useMemo, useCallback, useRef } from 'react';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import { isNil, equals } from 'ramda';

/**
 * @fileoverview Motivation: we want to read query parameters--search term, sorts, filter IDs, and possibly more
 * in the future--from the URL on mount. And we want to save changes in the query to the URL. This
 * unlocks a few neat behaviors, like sharable searches and using the Back button to return to a
 * previous search. For building a whole search page with this behavior, consider './useSearchState'.
 */

export type ParsedValue = string | number | boolean | Array<string | number | boolean | null> | null;
export type ParsedQuery = Record<string, ParsedValue | undefined>;

const queryStringOptions = {
  arrayFormat: 'bracket',
  parseNumbers: true,
  parseBooleans: true,
  skipNull: true,
} as const;
export const getQueryStringParams = (): ParsedQuery => queryString.parse(window.location.search, queryStringOptions);
export const stringifyParamsForQueryString = (params: ParsedQuery) => queryString.stringify(params, queryStringOptions);

export const devCheckParsedValue: (value: unknown) => void =
  import.meta.env.MODE === 'development'
    ? value => {
        const isPrimitive = val => ['number', 'string', 'boolean'].includes(typeof val);
        if (!isNil(value) && !isPrimitive(value) && !(Array.isArray(value) && value.every(isPrimitive))) {
          throw new Error(
            `A query key value is not a number, string, boolean, or array of those: ${JSON.stringify(value)}`,
          );
        }
      }
    : () => {};

/**
 * Returns a getter and setter pair like `useState`, but bidirectionally synced to a particular
 * `key` in the query string. Note that multiple usages of the same key will work fine, but they
 * will contend for the same value and update each other, so it's usually best to use a unique key.
 *
 * @example const [searchTerm, setSearchTerm] = useQueryState('search', '');
 * @param key the key in the query string to sync with
 * @param defaultValue the value to use when a query key is not present, ideally a top-level
 * constant if using an array
 * @param action 'replace' doesn't create a new entry in browsing history, 'push' does
 */
export default function useQueryKey(
  key: string,
  defaultValue: ParsedValue | undefined = undefined,
  action: 'replace' | 'push' = 'replace' /* or 'push' */,
): [ParsedValue | undefined, (set: React.SetStateAction<ParsedValue | undefined>) => void] {
  const location = useLocation();
  const history = useHistory();

  const queryValue: ParsedValue | undefined = useMemo(() => getQueryStringParams()[key], [key, location.search]);

  // Taking inspiration from React Query, this snippet makes sure that a value that stays logically
  // constant over time will have the same reference across renders, even with arrays, making
  // useMemo and useEffect dependency arrays work better. This is generally a problem any time we
  // repeatedly parse a string from an external source into a JS object.
  const dedupedQueryValue = useRef(queryValue);
  if (!equals(queryValue, dedupedQueryValue.current)) {
    dedupedQueryValue.current = queryValue;
  }

  const setQueryValue = useCallback(
    (newValueOrUpdateFn: React.SetStateAction<ParsedValue | undefined>) => {
      const newValue =
        typeof newValueOrUpdateFn === 'function' ? newValueOrUpdateFn(dedupedQueryValue.current) : newValueOrUpdateFn;

      devCheckParsedValue(newValue);

      const newParams = {
        ...getQueryStringParams(),
        [key]: equals(newValue, defaultValue) ? undefined : newValue, // omit defaultValue from query string
      };

      history[action]({
        search: stringifyParamsForQueryString(newParams),
      });
    },
    [key, defaultValue],
  );

  return [dedupedQueryValue.current ?? defaultValue, setQueryValue];
}
