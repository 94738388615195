import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    '& .rdw-editor-main': {
      border: `1px solid ${theme.palette.border}`,
      minHeight: 120,
      padding: '0 10px',
      '& a': {
        color: theme.palette.text.link,
        textDecoration: 'underline',
      },
      borderRadius: 4,
    },
    '& .rdw-option-wrapper': {
      border: 'none',
      margin: 0,
    },
  },

  error: {
    '& .rdw-editor-main': {
      border: `1px solid ${theme.palette.status.red}`,
    },
  },

  errorText: {
    color: theme.palette.status.red,
    paddingTop: 10,
  },

  noError: {
    display: 'none',
  },
}));
