import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    dsiplay: 'inline-flex',
    borderRadius: 4,
  },
  paper: {
    backgroundColor: theme.palette.gray0,
    display: 'inline-block',
    border: 'none',
    borderRadius: 4,
  },
  vertical: {
    flexDirection: 'column',
  },
  horizontal: {
    flexDirection: 'row',
  },

  // styles applied to child
  groupedVertical: {
    '&:first-child': {
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
    },
    '&:not(:first-child)': {
      marginTop: 2.5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      marginLeft: 5,
    },
    '&:not(:last-child)': {
      marginBottom: 2.5,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  groupedHorizontal: {
    '&:not(:first-child)': {
      marginLeft: 2.5,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '&:not(:last-child)': {
      marginRight: 2.5,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  sizeSmall: {
    height: 30,
    minWidth: 30,
  },
  sizeMedium: {
    height: 45,
    minWidth: 45,
  },
  sizeLarge: {
    height: 60,
    minWidth: 120,
  },
}));
