import { useMemo } from 'react';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export type Errors = {
  lengthError: string | null;
  customError: string | null;
};

type useErrorsOptions = {
  maxContentLength?: number;
};

export const getLengthErrorText = (maxContentLength?: number) => {
  if (!maxContentLength) return '';
  return `Content (including the background formatting) exceeds maximum length of ${maxContentLength} characters.`;
};

export const hasErrors = (errors: Errors) => {
  return Object.values(errors).some(error => error !== null);
};

export const getErrorMessages = (errors: Errors) => {
  return Object.values(errors).filter(error => error !== null);
};

export const isContentLengthValid = (htmlContent: string, maxContentLength?: number) => {
  if (!maxContentLength) return true;
  return htmlContent?.length <= maxContentLength;
};

export const useErrors = (
  htmlContent: string,
  isCustomError: boolean,
  customErrorText: string,
  options: useErrorsOptions,
) => {
  const errors: Errors = useMemo(
    () => ({
      customError: isCustomError ? customErrorText : null,
      lengthError: isContentLengthValid(htmlContent, options.maxContentLength)
        ? null
        : getLengthErrorText(options.maxContentLength),
    }),
    [htmlContent, isCustomError, customErrorText, options.maxContentLength],
  );

  return { errors, hasErrors: hasErrors(errors), errorMessagesArray: getErrorMessages(errors) };
};

export const buildEditorState = (editableContent: string) => {
  const contentBlock = htmlToDraft(editableContent);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

  return EditorState.createWithContent(contentState);
};
