import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Chip from '@material-ui/core/Chip';

import Icon from 'components/Icon';
import SearchableMenu from 'components/Menus/SearchableMenu';
import { FilterMenuSampleProps, Option, SearchableSelectProps, StatusItemProps } from './types';

import useStyles from './useStyles';

const FilterMenuSample = ({ children, selectedOptions, placeholder, handleRemove }: FilterMenuSampleProps) => {
  const [anchor, setAnchor] = useState(null);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const classes = useStyles();

  const handleOpen = event => {
    setAnchor(event.currentTarget);
    setPopoverWidth(event.currentTarget.offsetWidth);
  };
  const maxHeight = '70%';

  return (
    <>
      <div
        className={classes.filterMenuInput}
        onClick={handleOpen}
        role="button"
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            handleOpen(event);
          }
        }}
      >
        {selectedOptions.length === 0 && (
          <div className={classes.filterMenuPlaceholder}>{placeholder || 'Select...'}</div>
        )}

        <div className={classes.chipContainer}>
          {selectedOptions.map(option => (
            <Chip
              key={option.id}
              variant="outlined"
              label={option.name}
              className={classes.chip}
              deleteIcon={<Icon icon="clear" className={classes.icon} />}
              onDelete={() => {
                handleRemove(option);
              }}
            />
          ))}
        </div>
        <div>{anchor ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</div>
      </div>
      <Popover
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={!!anchor}
        onClose={() => {
          setAnchor(null);
        }}
        getContentAnchorEl={null}
        classes={{ paper: classes.content }}
        data-node="popover-menu"
        PaperProps={{ style: { width: popoverWidth, maxHeight } }}
      >
        {children}
      </Popover>
    </>
  );
};

const StatusItem = ({ item, labelPrefix }: StatusItemProps) => {
  const classes = useStyles();
  return (
    <div>
      <label id={`${labelPrefix}-item-${item.id}`} className={classes.label}>
        {item.name}
      </label>
    </div>
  );
};

const SearchableSelect = ({ options, placeholder, value, onChange }: SearchableSelectProps) => {
  const classes = useStyles();

  const handleSelect = (option: Option) => {
    const isOptionIncluded = value.some(item => item.id === option.id);
    if (!isOptionIncluded) {
      const sortedValue = [...value, option].sort((a, b) => a.name.localeCompare(b.name));

      onChange(sortedValue);
    }
  };

  return (
    <FilterMenuSample
      selectedOptions={value}
      handleRemove={(option: Option) => {
        onChange(value.filter(item => item !== option));
      }}
      placeholder={placeholder}
    >
      <SearchableMenu
        items={options}
        placeholder="Search..."
        renderItems={filteredItems => {
          const isGrouped = filteredItems.length > 0 && 'groupName' in filteredItems[0];
          if (isGrouped) {
            return filteredItems.map((group: any) => {
              return (
                <div key={group.groupName} className={classes.group}>
                  <div className={classes.groupName}>{group.groupName}</div>
                  {group?.services.map((item: Option) => {
                    return (
                      <div
                        key={item.id}
                        className={`${classes.listItem} ${value.includes(item) ? 'selected' : ''}`}
                        role="button"
                        tabIndex={0}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            handleSelect(item);
                          }
                        }}
                        onClick={() => {
                          handleSelect(item);
                        }}
                      >
                        <StatusItem item={item} labelPrefix="status-item" />
                      </div>
                    );
                  })}
                </div>
              );
            });
          }
          return filteredItems.map((item: Option) => {
            return (
              <div
                key={item.id}
                className={`${classes.listItem} ${value.includes(item) ? 'selected' : ''}`}
                role="button"
                tabIndex={0}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSelect(item);
                  }
                }}
                onClick={() => {
                  handleSelect(item);
                }}
              >
                <StatusItem item={item} labelPrefix="status-item" />
              </div>
            );
          });
        }}
      />
    </FilterMenuSample>
  );
};

export default SearchableSelect;
