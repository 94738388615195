import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  name: 'IntegrationsRepository',
  index(toolId, options = {}) {
    const url = Routes.api_v2_tool_integrations_path(toolId, options);
    return FetchHelpers.get(url);
  },
  show(toolId, id, options = {}) {
    const url = Routes.api_v2_tool_integration_path(toolId, id, options);
    return FetchHelpers.get(url);
  },
  create(toolId, attrs, options = {}) {
    const url = Routes.api_v2_tool_integrations_path(toolId, options);
    return FetchHelpers.post(url, attrs);
  },
  update(toolId, id, attrs, options = {}) {
    const url = Routes.api_v2_tool_integration_path(toolId, id, options);
    return FetchHelpers.patch(url, attrs);
  },
  destroy(toolId, id, options = {}) {
    const url = Routes.api_v2_tool_integration_path(toolId, id, options);
    return FetchHelpers.delete(url);
  },
};
