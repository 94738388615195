import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  name: 'ToolsRepository',
  index(params) {
    const url = Routes.api_v2_tools_path(params);
    return FetchHelpers.get(url);
  },

  show(id, params) {
    const url = Routes.api_v2_tool_path(id, params);
    return FetchHelpers.get(url);
  },

  compare(params) {
    const url = Routes.comparison_api_v2_tools_path(params);
    return FetchHelpers.get(url);
  },

  update(toolId, attrs) {
    const url = Routes.api_v2_tool_path(toolId);
    return FetchHelpers.patch(url, attrs);
  },
  gradeDetails(id) {
    const url = Routes.api_v2_tool_grade_details_path(id);
    return FetchHelpers.get(url);
  },
};
