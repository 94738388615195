import PropTypes from 'prop-types';
import { makePresenter } from 'utils/PropTypesPresenter';

const shape = {
  integrationType: PropTypes.string,
  target: PropTypes.string,
  url: PropTypes.string,
  configuration: PropTypes.string,
  notes: PropTypes.string,
  ownerEmail: PropTypes.string,
  key_name: PropTypes.string,
  toolId: PropTypes.string,
  toolName: PropTypes.string,
  auth_required: PropTypes.bool,
  name: PropTypes.string,
};

export type IntegrationConfigurationType = PropTypes.InferProps<typeof shape>;

function integrationMethod(integrationConfiguration: IntegrationConfigurationType) {
  switch (integrationConfiguration.integrationType) {
    case 'lti_13_configuration':
      return '1.3 by Paste JSON Code';
    case 'lti_13_url':
      return '1.3 JSON URL';
    case 'lti_13_dynamic_registration':
      return '1.3 Dynamic Registration';
    case 'lti_13_global_inherited_key':
      return '1.3 Global Inherited Key';
    case 'lti_11_url':
      return '1.1 From a Link';
    case 'lti_11_configuration':
      return '1.1 Paste XML';
    default:
      return 'Unknown';
  }
}

export default makePresenter(shape, {
  getIntegrationMethod(integrationConfiguration: IntegrationConfigurationType) {
    return integrationMethod(integrationConfiguration);
  },
  getName(integrationConfiguration: IntegrationConfigurationType) {
    return integrationConfiguration.name;
  },
  getDescription(integrationConfiguration: IntegrationConfigurationType) {
    return `${integrationConfiguration.toolName} Supports ${integrationMethod(integrationConfiguration)}`;
  },
  getAuthRequired(authRequired) {
    if (authRequired) {
      return 'Required';
    }
    return 'Not Required';
  },
});
