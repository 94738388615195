import React, { createContext, useContext, useState, ReactNode } from 'react';
import useModal, { VisibilityState } from 'hooks/useModal';

interface HeaderBarProviderType {
  children: ReactNode;
}

interface ButtonConfig {
  style: string;
  label: string;
  action: string;
  actionKey: string;
  id?: string;
  icon: string;
}

interface HeaderBarContextType {
  isVisible: boolean;
  handleShow: (componentName?: string) => void;
  handleClose: (componentName?: string) => void;
  visibilityState: VisibilityState;
  buttonsConfig: ButtonConfig[] | null;
  setButtonsConfig: (config: ButtonConfig[] | null) => void;
}

export const HeaderBarContext = createContext({} as HeaderBarContextType);

export const HeaderBarProvider = ({ children }: HeaderBarProviderType) => {
  const [isVisible, handleShow, handleClose, visibilityState] = useModal(false);
  const [buttonsConfig, setButtonsConfig] = useState<ButtonConfig[] | null>(null);

  return (
    <HeaderBarContext.Provider
      value={{
        isVisible,
        handleShow,
        handleClose,
        visibilityState,
        buttonsConfig,
        setButtonsConfig,
      }}
    >
      {children}
    </HeaderBarContext.Provider>
  );
};

export const useHeaderBarContext = () => useContext(HeaderBarContext);
