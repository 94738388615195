import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';

export default {
  name: 'IntegrationsConfigurationRepository',
  index(toolId, params) {
    const url = Routes.api_v2_tool_integration_configurations_path(toolId, params);
    return FetchHelpers.get(url);
  },
  show(toolId, id) {
    const url = Routes.api_v2_tool_integration_configuration_path(toolId, id);
    return FetchHelpers.get(url);
  },
  create(toolId, attrs, options = {}) {
    const url = Routes.api_v2_tool_integration_configurations_path(toolId, options);
    return FetchHelpers.post(url, attrs);
  },
  update(toolId, id, attrs, options = {}) {
    const url = Routes.api_v2_tool_integration_configuration_path(toolId, id, options);
    return FetchHelpers.patch(url, attrs);
  },
  destroy(toolId, id, options = {}) {
    const url = Routes.api_v2_tool_integration_configuration_path(toolId, id, options);
    return FetchHelpers.delete(url);
  },
  relatedData(toolId) {
    const url = Routes.related_data_api_v2_tool_integration_configurations_path(toolId);
    return FetchHelpers.get(url);
  },
};
