import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
  const addLink = {
    marginRight: 10,
    padding: 0,
    backgroundColor: theme.palette.gray1,
    border: `1px solid ${theme.palette.gray2}`,
    color: theme.palette.admin.pill.active.color,
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette.gray2,
    },
    '&.MuiButton-root:focus': {
      backgroundColor: theme.palette.gray1,
    },
  };

  return {
    addLink,
    contact: {
      ...addLink,
      width: 200,
    },
    connect: {
      ...addLink,
      width: 300,
    },
  };
});
