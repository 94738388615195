import React, { useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, InputLabel } from '@material-ui/core';
import { isEmpty } from 'ramda';
import { uuid } from 'uuidv4';

import getHooksForRepository from 'hooks/getHooksForRepository';
import IntegrationConfigurationsRepository from 'repositories/IntegrationConfigurationsRepository';
import { addErrorNotification } from 'sharedContainers/Notifications/NotificationsActions';
import IntegrationConfigurationPresenter from 'presenters/IntegrationConfigurationPresenter';
import Typography from 'components/Typography';
import IntegrationsContext from 'components/IntegrationsPanel/IntegrationsContext';
import TextArea from 'components/TextArea';
import { findPlacements, findServices } from '../ConfigurationTableModal/utils';

import useStyles from './useStyles';

interface ShowConfigurationProps {
  toolId: number | unknown;
  configId: string | (string | null)[];
  onClose: () => void;
  setTitle: Dispatch<SetStateAction<string>>;
}

const { useShow } = getHooksForRepository(IntegrationConfigurationsRepository);

const ShowConfiguration = ({ toolId, configId, onClose, setTitle }: ShowConfigurationProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { relatedData, isProvider, isSysAdmin } = useContext(IntegrationsContext);

  const { data, isLoading } = useShow(toolId, configId, {
    onError() {
      dispatch(addErrorNotification('Error fetching configuration'));
      onClose();
    },
  });

  useEffect(() => {
    data && setTitle(`${data.toolName}-${IntegrationConfigurationPresenter.getIntegrationMethod(data)}`);
  }, [data]);

  const {
    url,
    authLink,
    authRequired,
    ltiPlacementsIds,
    integrationType,
    name,
    configuration,
    globalInheritedKey,
    keyName,
    ownerEmail,
    ltiServicesIds,
    notes,
  } = data || {};

  const renderDefaultSection = (label: string, value: string) =>
    value && (
      <Grid item xs={12}>
        <Typography className={classes.title}>{label}</Typography>
        <Typography>{value}</Typography>
      </Grid>
    );

  const renderAuthSection = () => (
    <>
      {authLink && (
        <Grid item xs={12}>
          <Typography className={classes.title}>Auth Link</Typography>
          <a href={authLink} target="_blank" rel="noopener noreferrer" className={classes.link}>
            {authLink}
          </a>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={classes.title}>Auth Required</Typography>
        <Typography>{IntegrationConfigurationPresenter.getAuthRequired(authRequired)}</Typography>
      </Grid>
    </>
  );

  const renderUrlSection = (label: string) =>
    url && (
      <Grid item xs={12}>
        <Typography className={classes.title}>{label}</Typography>
        <div className={classes.link}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
        </div>
      </Grid>
    );

  const renderKeyNameSection = () => (
    <>
      {keyName && (
        <Grid item xs={12}>
          <Typography className={classes.title}>Key Name</Typography>
          <Typography>{keyName}</Typography>
        </Grid>
      )}
      {ownerEmail && (
        <Grid item xs={12}>
          <Typography className={classes.title}>Owner Email</Typography>
          <Typography>{ownerEmail}</Typography>
        </Grid>
      )}
    </>
  );

  const renderPlacementsSection = () =>
    !isEmpty(ltiPlacementsIds) && (
      <Grid item xs={12}>
        <Typography className={classes.title}>Placements</Typography>
        <div aria-label="navigation items" className={classes.listContainer}>
          {findPlacements(relatedData?.ltiPlacements, ltiPlacementsIds).map(({ name: text }) => (
            <div key={text} className={classes.listItem}>
              <label className={classes.listText}>{text}</label>
            </div>
          ))}
        </div>
      </Grid>
    );

  const renderTextArea = (label: string, value: string) => {
    const id = uuid();
    return value ? (
      <Grid item xs={12}>
        <InputLabel htmlFor={id} className={classes.title}>
          {label}
        </InputLabel>
        <TextArea value={value} rows={4} fullWidth disabled className={classes.textArea} id={id} />
      </Grid>
    ) : null;
  };

  const renderServicesSection = () => (
    <>
      {!isEmpty(ltiServicesIds) && (
        <Grid item xs={12}>
          <Typography className={classes.title}>Services</Typography>
          <div aria-label="navigation items" className={classes.listContainer}>
            {findServices(relatedData?.ltiServices, ltiServicesIds).map(({ name: text }) => (
              <div key={text} className={classes.listItem}>
                <label className={classes.listText}>{text}</label>
              </div>
            ))}
          </div>
        </Grid>
      )}
      {renderTextArea('Notes', notes)}
    </>
  );

  const typeToComponentMap = {
    lti_11_url: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderUrlSection('Config URL')}
        {renderAuthSection()}
        {renderPlacementsSection()}
      </>
    ),
    lti_11_configuration: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderDefaultSection('XML Configuration', configuration)}
        {renderAuthSection()}
        {renderPlacementsSection()}
      </>
    ),
    lti_13_url: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderUrlSection('JSON URL')}
        {renderKeyNameSection()}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_configuration: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderTextArea('JSON Code', configuration)}
        {renderKeyNameSection()}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_dynamic_registration: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderUrlSection('Dynamic Registration URL')}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
    lti_13_global_inherited_key: () => (
      <>
        {renderDefaultSection('Name', name)}
        {renderDefaultSection(
          'Description',
          `This configuration is managed by Instructure and accessed from the Inherited Developer Keys tab.${
            isProvider ? ' To make changes please contact partnership support.' : ''
          }`,
        )}
        {(isProvider || isSysAdmin) && renderDefaultSection('Client ID', globalInheritedKey)}
        {renderPlacementsSection()}
        {renderServicesSection()}
      </>
    ),
  };

  const renderComponentsForType = typeToComponentMap[integrationType] || (() => null);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <Grid container spacing={2}>
        {renderComponentsForType()}
      </Grid>
    </div>
  );
};

export default ShowConfiguration;
