import React from 'react';
import MUITooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import cn from 'classnames';
import useStyles from './useStyles';

interface Props extends TooltipProps {
  addKeyboardFocus?: boolean;
}

const Tooltip = ({
  classes: classesOverrides,
  className,
  children,
  enterDelay = 150,
  addKeyboardFocus = false,
  ...rest
}: Props) => {
  const classes = useStyles({ classes: classesOverrides });
  return (
    <MUITooltip
      classes={{
        tooltip: cn(classes.tooltip, className),
      }}
      placement="top"
      enterDelay={enterDelay}
      {...rest}
    >
      {addKeyboardFocus ? (
        <Button disableTouchRipple className={classes.button}>
          {children}
        </Button>
      ) : (
        children
      )}
    </MUITooltip>
  );
};

// eslint-disable-next-line lt-custom/safe-classes-prop
export default Tooltip;
