import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: `translate(-50%, -50%)`,
    maxWidth: '90vw',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border}`,
    borderRadius: 4,
    boxShadow: `0 2px 8px 2px ${theme.palette.CHANGEME[40]}`,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    '&:focus': {
      outline: 'none',
    },
  },

  title: {
    backgroundColor: theme.palette.gray0,
    padding: '12px 24px 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 50,
  },

  nontitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
  },

  titleText: {
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(18),
  },

  icon: {
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },

  content: {
    padding: 24,
    overflow: 'auto',
  },

  customContentStyle: {
    padding: '0 30px 0 24px',
    overflow: 'hidden',
  },

  actions: {
    margin: '0 24px 24px',
    display: 'flex',
    flex: '1 0 auto',
  },

  actionsAlignLeft: {
    justifyContent: 'flex-start',
  },

  actionsAlignCenter: {
    justifyContent: 'center',
  },

  actionsAlignRight: {
    justifyContent: 'flex-end',
  },

  noPadding: {
    padding: 0,
  },

  headerButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));
