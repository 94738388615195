import * as Yup from 'yup';
import { yupToFormErrors } from 'utils/Utils';
import { decamelize } from 'utils/keysConverter';

const CreateResourceSchema = Yup.object().shape({
  name: Yup.string().label('Title').required(),
  description: Yup.string().label('Description').required(),
  content: Yup.string().label('Content').required(),
});

export default {
  defaultAttributes() {
    return {
      id: '',
      name: '',
      description: '',
      content: '',
    };
  },
  validate(form) {
    try {
      CreateResourceSchema.validateSync(form, { abortEarly: false });
      return {};
    } catch (err) {
      return yupToFormErrors(err);
    }
  },

  attributesToSubmit(attributes) {
    return decamelize({ ...attributes });
  },
};
