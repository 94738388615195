import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';
import { decamelize } from 'utils/keysConverter';

export default {
  name: 'TagGroupsRepository',
  index(params, orgId) {
    // {
    //   meta: { count, totalCount, currentPage, numPages, perPage },
    //   tagGroups: [{
    //     id, name, organizationId,
    //     groupType: "system" | "custom",
    //     selectionType: "single" | "multi",
    //     tagInfo: [{
    //       tagId, name, isCustomTag, visibility, sortOrder, indentation: 1 or more
    //     }]
    //   }]
    // }
    if (orgId) {
      const url = Routes.api_v2_organization_tag_groups_path(orgId, params);
      return FetchHelpers.get(url);
    }
    const url = Routes.api_v2_tag_groups_path(params);
    return FetchHelpers.get(url);
  },

  create(orgId, tagGroup) {
    const url = Routes.api_v2_organization_tag_groups_path(orgId);
    return FetchHelpers.post(url, decamelize({ tagGroup }));
  },
  update(id, orgId, tagGroup) {
    const url = Routes.api_v2_organization_tag_group_path(orgId, id);
    return FetchHelpers.patch(url, decamelize({ tagGroup }));
  },
  destroy(id, orgId) {
    const url = Routes.api_v2_organization_tag_group_path(orgId, id);
    return FetchHelpers.delete(url);
  },
};
