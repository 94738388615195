import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import getHooksForRepository from 'hooks/getHooksForRepository';
import IntegrationsRepository from 'repositories/IntegrationsRepository';
import { addErrorNotification } from 'sharedContainers/Notifications/NotificationsActions';
import Button from 'components/Button';

interface ShowResourceProps {
  toolId: number | unknown;
  resourceId: string | (string | null)[];
  onClose: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const { useShow } = getHooksForRepository(IntegrationsRepository);

const ShowResource = ({ toolId, resourceId, onClose, setTitle }: ShowResourceProps) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    onClose();
    return null;
  };

  const { data, isLoading } = useShow(toolId, resourceId, {
    onError() {
      dispatch(addErrorNotification('Error fetching resource'));
      closeModal();
    },
  });

  useEffect(() => {
    setTitle(data?.name || 'Tool Integration Resource');
  }, [data]);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div>
      <p>Description: {data.description}</p>
      <p>Integration Resource: {data.name}</p>
      <div>
        Content: <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </div>
      <Button onClick={closeModal} data-node="view-close-button">
        Close
      </Button>
    </div>
  );
};

export default ShowResource;
