export default theme => ({
  textareaRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.MuiInputBase-root.Mui-disabled': {
      color: theme.palette.black,
      '-webkit-text-fill-color': theme.palette.black,
    },
  },

  textareaInput: {
    borderRadius: 2,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.border}`,
    padding: '10px',
  },

  error: {
    '& p': {
      color: theme.palette.statuses.danger,
    },
  },

  textareaError: {
    border: `1px solid ${theme.palette.statuses.danger}`,
  },
});
