import React, { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

/*
 * Sometimes, unrelated components would like to modify each other's states,
 * and it doesn't make sense to lift state up or put it in Redux. These hooks
 * provide a lightweight way to send and listen for events (think pubsub), which
 * enables decoupled communication between components.
 */

type Listener = (event: string, payload?: any) => void;
interface EventFunctions {
  sendEvent: Listener;
  listen(listener: Listener): void;
  stopListening(listener: Listener): void;
}
const EventContext = createContext<EventFunctions | null>(null);

/**
 * Wrap a tree of components in this provider to enable sending and receiving of events.
 */
export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  // In development, make sure this provider isn't nested, as that can only cause confusion.
  if (import.meta.env.MODE === 'development') {
    // Since the if condition is set to true or false at build time, we can put a hook in a conditional.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const parentContextCheck = useContext(EventContext);
    if (parentContextCheck !== null) {
      throw new Error(
        'An EventProvider was nested inside another EventProvider. This is not allowed, since it causes confusion about which components listen to which events.',
      );
    }
  }
  const allEventListeners = useRef([] as Array<Listener>);
  const value = useMemo(
    (): EventFunctions => ({
      sendEvent: (event, payload) => allEventListeners.current.forEach(listener => listener(event, payload)),
      listen: listener => allEventListeners.current.push(listener),
      stopListening: listener => {
        const index = allEventListeners.current.indexOf(listener);
        if (index !== -1) {
          allEventListeners.current.splice(index, 1);
        }
      },
    }),
    [],
  );
  return <EventContext.Provider value={value}>{children}</EventContext.Provider>;
};
EventProvider.propTypes = {
  children: PropTypes.node,
};

/**
 * Higher order component to wrap a component in an EventProvider; the `Props` type parameter
 * preserves typing/autocomplete for the wrapped component.
 */
export function withEventProvider<Props>(Component: React.ComponentType<Props>) {
  return (props: Props) => (
    <EventProvider>
      <Component {...props} />
    </EventProvider>
  );
}

/**
 * Pass a callback of type (event, payload) => void, and respond to incoming events.
 */
export const useListenForEvents = (listener: Listener) => {
  const contextFunctions = useContext(EventContext);
  if (import.meta.env.MODE === 'development' && contextFunctions === null) {
    throw new Error('A component using useListenForEvents() should be wrapped in an <EventProvider />.');
  }
  const { listen, stopListening } = contextFunctions!;
  const listenerRef = useRef(listener);
  listenerRef.current = listener;
  useEffect(() => {
    const cb = (event, payload) => {
      if (listenerRef.current) {
        listenerRef.current(event, payload);
      }
    };
    listen(cb);
    return () => stopListening(cb);
  }, []);
};

/**
 * Use the returned function to send (event, payload) to any
 * components that might be listening.
 */
export const useSendEvent = () => {
  const contextFunctions = useContext(EventContext);
  if (import.meta.env.MODE === 'development' && contextFunctions === null) {
    throw new Error('A component using useSendEvent() should be wrapped in an <EventProvider />.');
  }
  const { sendEvent } = contextFunctions!;
  return sendEvent;
};
