export default theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },

  icon: {
    marginBottom: 17,
  },

  title: {
    color: theme.palette.CHANGEME[1],
    fontSize: theme.typography.pxToRem(20),
    marginBottom: 5,
  },

  description: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
  },
});
