import React from 'react';
import cn from 'classnames';
import MUITypography, { TypographyProps } from '@material-ui/core/Typography';

import useStyles from './useStyles';

// https://v4.mui.com/guides/typescript/#usage-of-component-prop
type Props<C extends React.ElementType> = TypographyProps<C, { component?: C }> & {
  active?: boolean;
  disabled?: boolean;
  className?: string;
};

function Typography<C extends React.ElementType>({
  className,
  variant = 'body1',
  active = false,
  disabled = false,
  children,
  ...rest
}: Props<C>) {
  const classes = useStyles();

  return (
    <MUITypography
      variant={variant}
      className={className}
      classes={{
        root: cn({
          [classes.active]: active,
          [classes.disabled]: disabled,
          [`classes.${variant}`]: classes[variant],
        }),
      }}
      {...rest}
    >
      {children}
    </MUITypography>
  );
}

export default Typography;
