import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MUITextField, { TextFieldProps } from '@material-ui/core/TextField';
import Typography from 'components/Typography';
import useStyles from './useStyles';

type Props = TextFieldProps & { maxLength?: number };

const TextField = ({ children, maxLength, helperText, value, onChange, ...rest }: Props) => {
  const classes = useStyles();
  const showMaxLength = maxLength! > 0 && typeof value === 'string';

  return showMaxLength ? (
    <MUITextField
      value={value}
      helperText={
        <>
          {helperText}
          <Typography
            color={value.length > maxLength! ? 'error' : 'textSecondary'}
            variant="caption"
            className={classes.maxLength}
          >
            {value.length}/{maxLength}
          </Typography>
        </>
      }
      onChange={
        onChange &&
        (e => {
          if (e.target.value.length > maxLength!) {
            // patching event seems to work, since this is a React synthetic event
            e.target.value = e.target.value.substring(0, maxLength);
          }
          return onChange(e);
        })
      }
      {...rest}
      inputProps={{
        ...rest.inputProps,
        // maxLength directly on an input generally does what we want, but we still wrap onChange
        // to truncate the text just in case the browser doesn't handle it.
        maxLength,
      }}
      FormHelperTextProps={{
        ...rest.FormHelperTextProps,
        // @ts-ignore
        component: 'div',
        className: cn(rest.FormHelperTextProps?.className, classes.maxLengthHelperText),
      }}
      error={rest.error || value?.length > maxLength!}
    >
      {children}
    </MUITextField>
  ) : (
    <MUITextField
      helperText={helperText}
      value={value}
      onChange={onChange}
      {...rest}
      // if (maxLength && !showMaxLength), we still want to add maxLength to input element
      inputProps={maxLength ? { ...rest.inputProps, maxLength } : rest.inputProps}
    >
      {children}
    </MUITextField>
  );
};

TextField.propTypes = {
  children: PropTypes.node,
  maxLength: PropTypes.number,
  helperText: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextField;
