import { pathOr } from 'ramda';
import keyMirror from 'keymirror';
import { uuid } from 'uuidv4';
import NotificationConstants from './NotificationConstants';

const DEFAULT_NOTIFICATION_TIMEOUT = 10000;

export const notificationTypes = keyMirror({
  SUCCESS: null,
  ERROR: null,
  INFO: null,
});

export function closeNotification(notificationId) {
  return { type: NotificationConstants.NOTIFICATION_CLOSED, notificationId };
}

export function addNotification(body, params = {}) {
  const actions = pathOr([], ['actions'], params);
  const timeout = pathOr(DEFAULT_NOTIFICATION_TIMEOUT, ['timeout'], params);
  const type = pathOr(notificationTypes.INFO, ['type'], params);

  return dispatch => {
    const notification = { id: uuid(), body, actions, type };
    dispatch({ type: NotificationConstants.NOTIFICATION_ADDED, notification });

    setTimeout(() => {
      dispatch(closeNotification(notification.id));
    }, timeout);
  };
}

export function addSuccessNotification(body, params = {}) {
  const type = notificationTypes.SUCCESS;
  return addNotification(body, { ...params, type });
}

export function addErrorNotification(body, params = {}) {
  const type = notificationTypes.ERROR;
  return addNotification(body, { ...params, type });
}

export function addNotificationWithoutAutoClose(body, params = {}) {
  const actions = pathOr([], ['actions'], params);
  const type = pathOr(notificationTypes.INFO, ['type'], params);
  const id = uuid();

  const notification = { id, body, actions, type };
  return { type: NotificationConstants.NOTIFICATION_ADDED, notification };
}

export function addSuccessNotificationWithoutAutoClose(body, params = {}) {
  const type = notificationTypes.SUCCESS;
  return addNotificationWithoutAutoClose(body, { ...params, type });
}

export function addErrorNotificationWithoutAutoClose(body, params = {}) {
  const type = notificationTypes.ERROR;
  return addNotificationWithoutAutoClose(body, { ...params, type });
}
