import { chain, prop } from 'ramda';
import { Group, Service, Placement } from './types';

export const findServices = (services: Group[], serviceIds: number[]): Service[] => {
  const allServices = chain(prop('services'), services); // flattens the array of services
  return allServices.filter(service => serviceIds.includes(service.id));
};

export const findPlacements = (placements: Placement[], placementIds: number[]) => {
  return placements.filter(placement => placementIds.includes(placement.id));
};
