import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from 'components/Icon';
import useStyles from './useStyles';

const NoResults = ({ classes, className, icon, iconWidth, iconHeight, title, description, ...rest }) => {
  const classNames = {
    className: cn({
      [classes.root]: true,
      [className]: !!className,
    }),
  };

  return (
    <div {...classNames}>
      <Icon icon={icon} width={iconWidth} height={iconHeight} className={classes.icon} {...rest} />
      <div className={classes.title}>{title}</div>

      <div className={classes.description}>{description}</div>
    </div>
  );
};

NoResults.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
};

NoResults.defaultProps = {
  className: null,
  icon: 'emptyState',
  iconWidth: 120,
  iconHeight: 94,
  title: 'Aww shucks! No results.',
  description: 'Sorry, we couldn’t find any matching results.',
};

export default withStyles(useStyles)(NoResults);
