import React, { ReactNode } from 'react';
import { partial } from 'ramda';
import type { Tool } from 'presenters/ToolPresenter';

import ToolsRepository from 'repositories/ToolsRepository';
import CompanyToolsRepository from 'repositories/Companies/ToolsRepository';
import AdminToolsRepository from 'repositories/Admin/ToolsRepository';
import IntegrationConfigurationsRepository from 'repositories/IntegrationConfigurationsRepository';

import getHooksForRepository from 'hooks/getHooksForRepository';

import { RepositoryHooks, Overrides } from 'hooks/getHooksForRepository.types';
import UserPresenter from 'presenters/UserPresenter';
import { useCurrentUser } from 'hooks/useSelector';
import IntegrationsContext from './IntegrationsContext';

type ToolRepositoryTypes = typeof ToolsRepository | typeof CompanyToolsRepository | typeof AdminToolsRepository;
export type ToolRepositoryHooksTypes = RepositoryHooks<ToolRepositoryTypes, Overrides<ToolRepositoryTypes>>;

interface IntegrationsPanelProps {
  toolRepositoryHooks?: ToolRepositoryHooksTypes;
  companyId?: number | string | unknown;
  tool: Tool;
  isProvider?: boolean;
  canEdit?: boolean;
  children: ReactNode;
}

const defaultToolRepositoryHooks = getHooksForRepository(ToolsRepository, { index: 'infinite' });

const { useRelatedData } = getHooksForRepository(IntegrationConfigurationsRepository, {
  relatedData: 'query',
});

const IntegrationsPanel = ({
  toolRepositoryHooks = defaultToolRepositoryHooks as ToolRepositoryHooksTypes,
  companyId = null,
  tool,
  canEdit = false,
  isProvider = false,
  children,
}: IntegrationsPanelProps) => {
  const wrappedRepoFunction = (funcType: string, repoFunc, ...options) => {
    const f = repoFunc(...options)[funcType];
    return isProvider && companyId ? partial(f, [companyId, tool.id]) : partial(f, [tool.id]);
  };

  const useUpdateToolVendorComment = (...options) => {
    const wrappedFunc = wrappedRepoFunction('mutate', toolRepositoryHooks.useUpdate, ...options);
    return vendorComment => {
      // companyId is required on the tool in SysAdmin, otherwise it removes the company when saving comment
      if (companyId) {
        return wrappedFunc({ tool: { vendorComment, companyId } });
      }
      return wrappedFunc({ tool: { vendorComment } });
    };
  };

  const { data } = useRelatedData(tool.id, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
  const currentUser = useCurrentUser();

  const isSysAdmin = (currentUser != null ? UserPresenter.isSystemAdministrator(currentUser) : false) || false;

  return (
    <IntegrationsContext.Provider
      value={{
        toolId: tool.id,
        tool,
        isProvider,
        isSysAdmin,
        canEdit,
        useUpdateToolVendorComment,
        relatedData: data,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsPanel;
